<!-- 充值---收银台 --支付宝-微信-->
<template>
  <div class="rechargepay">
    <el-row class='head'>
      <el-col :span="12">
        <logotitle title='收银台'></logotitle>
      </el-col>
      <el-col :span="12">
      </el-col>
    </el-row>
    <payalipayre calss='payalipayre' :codeDate='codeDate' :paydata='paydata'  :paytype='parseInt(this.$route.query.paytype)'></payalipayre>
  </div>
</template>
<script>
import logotitle from '@/components/logotitle/logotitle.vue';//图片标题组件
import payalipayre from '@/components/payalipayre/payalipayre.vue';//支付组件
export default {
  name: 'rechargepay',
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return {
      htmltitle:'收银台-微微定',
      paydata:null,
      codeDate:null,
    }
  },
  components: {
    logotitle,
    payalipayre
  },
  props: {
    msg: String
  },
  methods:{
    localReset(){
      if(this._spf(this.$route.query.paytype)==0){
        // 支付宝
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'UserCenter/PayDepositOrder?orderNumber='+this._spf(this.$route.query.orderNumber)+'&payTyepe=1',this.keys())
          .then(res => {
              if(res.data.Success){
                if(res.data.Success){
                  var _obj = {};
                  _obj.data = res.data.Data.orderName
                  _obj.data1 = res.data.Data.PayAmount
                  _obj.data2 = res.data.Data.codeurl
                  if(res.data.Data.state!=0){
                    this.codeDate= res.data.Data.CreateDate
                  }
                  this.$set(this,'paydata',_obj)
                }else{
                  this.$MessageBox.alert(res.data.Error,{type:'error'});
                }
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
              console.log('系统异常')
            })
        },exp:true,catch:()=>{
              this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
              // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }
      if(this._spf(this.$route.query.paytype)==1){
        // 微信
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'UserCenter/PayDepositOrder?orderNumber='+this._spf(this.$route.query.orderNumber)+'&payTyepe=2',this.keys())
          .then(res => {
              if(res.data.Success){
                if(res.data.Success){
                  var _obj = {};
                  _obj.data = res.data.Data.orderName
                  _obj.data1 = res.data.Data.PayAmount
                  _obj.data2 = res.data.Data.codeurl
                  this.codeDate= res.data.Data.CreateDate
                  this.$set(this,'paydata',_obj)
                }else{
                  this.$MessageBox.alert(res.data.Error,{type:'error'});
                }
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
              console.log('系统异常')
            })
        },exp:true,catch:()=>{
              this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
              // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.localReset()
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.rechargepay {
  width: 100%;background: #fff;
  .head {
    width: 1200px;margin:0 auto;padding-top:20px;padding-bottom:20px;
    .buystep {
      float:right;margin-top:10px;
    }
  }
  .payalipayre {
    width: 1200px;margin:0 auto;padding-bottom:30px;
  }
}
</style>
